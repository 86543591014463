import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import ELink from "../Core/ExternalLink"
import styled from 'styled-components'
import { isInternalLink, getRelativeLink } from '../../services/core/links'
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import ContentContainer from "../Core/Containers/ContentContainer"
import { H3Style, H5Style } from "../Core/Styles/typography";
import { Row, Col } from "styled-bootstrap-grid";
import LogoUrl from "../../resources/img/svg/logo.svg";

const WEBSITE_TITLE = process.env.WEBSITE_TITLE

const Container = styled.footer`
  background: ${colors.greyLight};
  padding: 60px 0 36px;

  @media (min-width: ${breakpoints.md}) {
    padding: 60px 0 24px;
  }
`

const Header = styled.h2`
  ${H3Style};
  margin-bottom: 40px;
  max-width: 300px;

  @media(min-width: ${breakpoints.md}) {
    max-width: 350px;
  }

`

const LinksBlock = styled.ul`
  margin-bottom: 80px;
  li {
    ${H5Style};
    font-weight: 600;
    margin-bottom: 12px;
    :last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  @media(min-width: ${breakpoints.md}) {
    margin-bottom: 0;
  }
`


const InternalLink = styled(Link)``

const ExternalLink = styled(ELink)``

const LocationsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px auto 0;

  @media (min-width: ${breakpoints.md}) {
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Location = styled.div`
  margin-right: 40px;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.md}) {
    margin-right: 0px;
    margin-bottom: 0px;
  }
`;

const LocationsHeader = styled.h3`
  ${fonts.sourceSerif};
  font-size: 14px;
  line-height: 22px;

  @media (min-width: ${breakpoints.md}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const LocationsP = styled.p`
  ${fonts.openSans};
  display: none;

  a {
    text-decoration: none;
    color: inherit;
  }

  @media (min-width: ${breakpoints.md}) {
    display: block;
    font-size: 12px;
    line-height: 16px;
    margin-top: 6px;
  }
`;

const CopyrightBlock = styled.div`
  margin: 7px auto;

  svg {
    max-height: 18px;
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: 54px;
  }
`

const CopyrightText = styled.p`
  ${fonts.openSans};
  color: ${colors.greyMedium};
  font-size: 12px;
  line-height: 16px;
  margin-top: 6px;
`;

const Logo = styled.img`
  height: 18px;
`


const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      contentfulFooter(slug: {eq: "footer"}) {
        heading
        links {
          displayText
          url
        }
        locations {
          city
          telephone
          email
        }
      }
    }
  `)
  const footer = data.contentfulFooter
  return (
    <Container>
      <ContentContainer>
        <Row>
          <Col>
            <Header>{footer.heading}</Header>
          </Col>
          <Col md={5}>
            <LinksBlock>
              {footer.links.map((link, index) => {
                const { url, displayText } = link;
                return (
                  <li key={index}>
                    {isInternalLink(url) ? (
                      <InternalLink
                        to={getRelativeLink(url)}
                        key={index}
                      >
                        {displayText}
                      </InternalLink>
                    ) : (
                      <ExternalLink href={url} key={index}>
                        {displayText}
                      </ExternalLink>
                    )}
                  </li>
                );
              })}
            </LinksBlock>
          </Col>
          <Col md={6} mdOffset={1}>
            <LocationsBlock>
              {footer.locations.map((location, index) => {
                const { city, telephone, email } = location;
                return (
                  <Location key={index}>
                    <LocationsHeader>{city}</LocationsHeader>
                    {telephone ? <LocationsP>{telephone}</LocationsP> : null}
                    {email ? (
                      <LocationsP>
                        <a href={`mailto:${email}`}>{email}</a>
                      </LocationsP>
                    ) : null}
                  </Location>
                );
              })}
            </LocationsBlock>
          </Col>
        </Row>

        <CopyrightBlock>
          <Logo src={LogoUrl} alt={WEBSITE_TITLE} />
          <CopyrightText>
            © {WEBSITE_TITLE} {new Date().getFullYear()}. All Rights Reserved
          </CopyrightText>
        </CopyrightBlock>
      </ContentContainer>
    </Container>
  );
}

export default Footer
