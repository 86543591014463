import React from 'react'
import styled from "styled-components"
import breakpoints from "../../../styles/breakpoints"

const Container = styled.div`
  width: 100%;
`

const Content = styled.div`
  padding: 0 26px;
  max-width: ${breakpoints.xxl};
  margin: auto;

  @media (min-width: ${breakpoints.md}) {
    padding: 0 25px;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 0 76px;
  }
`

const ContentContainer = ({children}) => {
  return (
    <Container>
      <Content>
        {children}
      </Content>
    </Container>
  )
}

export default ContentContainer
