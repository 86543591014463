import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import ELink from "../../Core/ExternalLink";
import styled from "styled-components";
import { isInternalLink, getRelativeLink } from "../../../services/core/links";
import { setBodyOverflow } from "../../../services/core/browser";
import Logo from "../../../resources/img/svg/logo.svg";
import ContentContainer from "../../Core/Containers/ContentContainer";
import { ReactComponent as BurgerIcon } from "../../../resources/img/svg/burger-icon.svg";
import { ReactComponent as CloseIcon } from "../../../resources/img/svg/close-icon.svg";
import colors from "../../../styles/colors";
import MobileOverlay from "./components/MobileOverlay"
import breakpoints from "../../../styles/breakpoints";
import fonts from "../../../styles/fonts";
import { useLocation } from "@reach/router";

const WEBSITE_TITLE = process.env.WEBSITE_TITLE;

const Container = styled.header`
  padding: 25px 0;
  position: fixed;
  width: 100%;
  top: 0;
  background: ${colors.white};
  z-index: 999;

  @media (min-width :${breakpoints.md}) {
    padding: 23px 0; 
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DesktopNav = styled.nav`
  display: none;
  @media (min-width: ${breakpoints.md}) {
    display: flex;
    column-gap: 27px;
  }
`;

const LinkContainer = styled.div`
  ${fonts.sourceSerif};
  color: ${colors.greyMedium};
  font-size: 16px;
  line-height: 1.5;

  .active {
    color: ${colors.greyDark};
  }

  :hover {
    color: ${colors.greyDark};
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

const InternalLink = styled(Link)``;

const ExternalLink = styled(ELink)``;

const Toggle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  svg {
    width: 23px;
  }

  @media(min-width: ${breakpoints.md}) {
    display: none;
  }
`

const Header = () => {
  const location = useLocation()
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      contentfulHeader(slug: { eq: "header" }) {
        links {
          displayText
          url
        }
      }
    }
  `);
  const header = data.contentfulHeader;

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)

  useEffect(() => {
    if (isMobileNavOpen) {
      setBodyOverflow(true)
    } else {
      setBodyOverflow(false)
    }
  }, [isMobileNavOpen])



  return (
    <Container>
      <ContentContainer>
        <HeaderContainer>
          <InternalLink to="/">
            <img src={Logo} alt={WEBSITE_TITLE} />
          </InternalLink>
          <Toggle onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}>
            {isMobileNavOpen ? <CloseIcon /> : <BurgerIcon />}
          </Toggle>
          <DesktopNav>
            {header.links.map((link, index) => {
              const { url, displayText } = link;
              const isWorkPage = url.includes("our-work")
              return (
                <LinkContainer key={index}>
                  {isInternalLink(url) ? (
                    <InternalLink
                      to={getRelativeLink(url)}
                      key={index}
                      activeClassName={"active"}
                      className={isWorkPage && location.pathname.includes("our-work") ? "active" : ""}
                    >
                      {displayText}
                    </InternalLink>
                  ) : (
                    <ExternalLink href={url} key={index}>
                      {displayText}
                    </ExternalLink>
                  )}
                </LinkContainer>
              );
            })}
          </DesktopNav>
          <MobileOverlay open={isMobileNavOpen} links={header.links} handleClose={setIsMobileNavOpen}/>
        </HeaderContainer>
      </ContentContainer>
    </Container>
  );
};

export default Header;
