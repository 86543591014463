import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import {
  isInternalLink,
  getRelativeLink,
} from "../../../../services/core/links";
import ELink from "../../../Core/ExternalLink";
import ContentContainer from "../../../Core/Containers/ContentContainer";
import colors from "../../../../styles/colors";
import breakpoints from "../../../../styles/breakpoints";
import fonts from "../../../../styles/fonts";
import { useLocation } from "@reach/router";

const StyledNav = styled.nav`
  position: fixed;
  background: white;
  height: 100%;
  width: 100%;
  top: 70px;
  right: ${(props) => (props.open ? "0" : "-100vw")};
  max-height: calc(100vh - 70px);
  overflow: scroll;
  transition: all 0.5s ease-in;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

const LinkList = styled.ul`
  padding: 25px 0;

  li {
    ${fonts.sourceSerif};
    font-size: 20px;
    line-height: 1.2;
    color: ${colors.greyDark};
    padding: 15px 0;

    a {
      color: inherit;
      text-decoration: none;
      position: relative;
    }
  }
`;

const InternalLink = styled(Link)``;

const ExternalLink = styled(ELink)``;

const MobileOverlay = ({ open, handleClose, links }) => {
  const location = useLocation();
  const closeIfActive = (e) => {
    if (e.currentTarget.className.includes("active")) {
      e.preventDefault();
      handleClose(false);
    }
  };

  return (
    <StyledNav open={open ? 1 : 0}>
      <ContentContainer>
        <LinkList>
          <li>
            <Link to={"/"}>Home</Link>
          </li>
          {links.map((link, index) => {
            const { url, displayText } = link;
            const isWorkPage = url.includes("our-work");
            return (
              <li key={index}>
                {isInternalLink(url) ? (
                  <InternalLink
                    to={getRelativeLink(url)}
                    key={index}
                    onClick={(e) => closeIfActive(e)}
                    activeClassName={"active"}
                    partiallyActive={true}
                    className={
                      isWorkPage && location.pathname.includes("our-work")
                        ? "active"
                        : ""
                    }
                  >
                    {displayText}
                  </InternalLink>
                ) : (
                  <ExternalLink href={url} key={index}>
                    {displayText}
                  </ExternalLink>
                )}
              </li>
            );
          })}
        </LinkList>
      </ContentContainer>
    </StyledNav>
  );
};

export default MobileOverlay;
