import colors from "../../../styles/colors";
import fonts from "../../../styles/fonts";
import breakpoints from "../../../styles/breakpoints";

const H1Style = `
  ${fonts.sourceSerif};
  color: ${colors.greyDarker};
  font-size: 40px;
  line-height: 54px;

  @media (min-width: ${breakpoints.md}) {
		font-size: 60px;
		line-height: 1.3;
  }
`;

const H2Style = `
  ${fonts.sourceSerif};
  color: ${colors.greyDark};
  font-size: 32px;
  line-height: 44px;

  @media (min-width: ${breakpoints.md}) {
		font-size: 50px;
		line-height: 69px;
  }

`;

const H3Style = `
  ${fonts.sourceSerif};
  color: ${colors.greyDark};
    font-size: 26px;
    line-height: 36px;

  @media (min-width: ${breakpoints.md}) {
		font-size: 36px;
		line-height: 49px;
  }

`;
const H4Style = `
  ${fonts.sourceSerif};
  color: ${colors.greyDark};
      font-size: 20px;
      line-height: 27px;

  @media (min-width: ${breakpoints.md}) {
				font-size: 24px;
				line-height: 33px;
  }
  

`;
const H5Style = `
  ${fonts.openSans};
  color: ${colors.greyDark};
  font-size: 16px;
  line-height: 26px;

  @media (min-width: ${breakpoints.md}) {
		font-size: 18px;
		line-height: 28px;
  }
`;
const H6Style = `
  ${fonts.openSans};
  color: ${colors.greyDark};
  font-size: 14px;
  line-height: 19px;

  @media (min-width: ${breakpoints.md}) {
		font-size: 14px;
		line-height: 24px;
  }
  
`;

export { H1Style, H2Style, H3Style, H4Style, H5Style, H6Style };
