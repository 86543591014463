function isBrowser() {
  return typeof window !== "undefined";
}

function setBodyOverflow(isOpen) {
  let overflow;
  let style;

  if (isOpen) {
    overflow = "hidden";
  }

  let documentBody = document.getElementsByTagName("body")[0];

  style = "overflow: " + overflow + ";";

  let ua = window.navigator.userAgent;
  let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);

  if (isOpen && iOS) {
    style += "height: 100%; position: fixed;";
  } else {
    style += "position: relative";
  }

  documentBody.style = style;
}

export { isBrowser, setBodyOverflow };
