import React from "react";
import styled from "styled-components";
import breakpoints from "../../../styles/breakpoints";

const Container = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.smMargin || props.block ? "60px" : "100px")};

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: ${(props) => (props.smMargin && !props.block ? "100px" : "150px")};
  }
`;

const Content = styled.div`
  max-width: ${breakpoints.xxl};
  margin: auto;
  overflow: ${(props) => (props.visibleOverflow ? "visible" : "hidden")};
`;

const BlockContainer = ({ children, smMargin, visibleOverflow, block }) => {
  return (
    <Container smMargin={smMargin ? 1 : 0} block={block ? 1 : 0}>
      <Content visibleOverflow={visibleOverflow? 1 : 0}>{children}</Content>
    </Container>
  );
};

export default BlockContainer;
