const colors = {
  white: "#FFFFFF",
  black: "#000000",
  "greyDarker": "#333333",
  "greyDark": "#4F4F4F",
  "greyMedium": "#828282",
  "greyLight": "#F2F2F2",
};

export default colors;
