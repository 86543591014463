import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import fonts from "../styles/fonts";
import colors from "../styles/colors";
import breakpoints from "../styles/breakpoints";

const fontFace = (fontFamily, woff, woff2) => {
  return `
    @font-face {
      font-family: '${fontFamily}';
      src: url(${woff2}) format('woff2'),
      url(${woff}) format('woff');
      font-weight: normal;
      font-style: normal;
    }
  `;
};

const GlobalStyle = createGlobalStyle`
  ${reset}

  body {
    ${fonts.workSans};
    color: ${colors.greyDark};

		h1 {
      ${fonts.sourceSerif};
      font-size: 40px;
      line-height: 54px;
      color: ${colors.greyDarker};
    }

    h2 {
      ${fonts.sourceSerif};
      font-size: 32px;
      line-height: 44px;
      color: ${colors.greyDark};
    }

    h3 {
      ${fonts.sourceSerif};
      font-size: 26px;
      line-height: 36px;
      color: ${colors.greyDark};
    }

    h4 {
      ${fonts.sourceSerif};
      font-size: 20px;
      line-height: 27px;
      color: ${colors.greyDark};
    }

    h5 {
      ${fonts.sourceSerif};
      font-size: 16px;
      line-height: 26px;
      color: ${colors.greyDark};
    }

    h6 {
      ${fonts.sourceSerif};
      font-size: 14px;
      line-height: 19px;
      color: ${colors.greyDark};
    }

		@media (min-width :${breakpoints.md}) {
			h1 {
				font-size: 60px;
				line-height: 1.3;
			}

			h2 {
				font-size: 50px;
				line-height: 69px;
			}

			h3 {
				font-size: 36px;
				line-height: 49px;
			}

			h4 {
				font-size: 24px;
				line-height: 33px;
			}

			h5 {
				font-size: 18px;
				line-height: 28px;
			}

			h6 {
				font-size: 14px;
				line-height: 24px;
			}
		}
  }
`;

export default GlobalStyle;
